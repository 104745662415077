

.Landing__hello {
  padding-top: 80px;
  border-top: 1px solid #e0e0e0;
  text-align: center;
  color: rgb(179, 179, 179);
  margin-top: -206px;
  margin-bottom: 300px;
  width: 1200px !important;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

.Landing__hello__text--emphasize {
  font-size: 25px;
  font-weight: 600;
  color: #747474;
  margin-bottom: 40px;
}

.Landing__block {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 1200px !important;
  word-wrap: break-word;
}

.Landing__block__name {
  width: 100%;
  color: #ff3838;
  padding-bottom: 15px;
  font-size: 34px;
  font-weight: 700;
  border-bottom: 1px solid #ff3838;
  margin-bottom: 50px;
}

.Landing__block__name-hyper {
  margin-top: 250px;
  font-family: 'Dela gothic one';
  font-size: 70px;
  color: #ff383800;
  -webkit-text-stroke: #ff3838 0.5px;
  -webkit-text-stroke-width: 0.5px;
}

.Landing__block__name-hyper:hover {
  color: #ff3838;
  -webkit-text-stroke-width: 0px;
  cursor: pointer;
}

.Landing__block__name-small {
  font-size: 22px;
  margin-bottom: 10px;
  border-bottom: none;
  font-weight: 600;
  color: #888888;
}

.Landing__block__islands {
  margin-bottom: 50px;
}

.Landing__block__islands__island {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.8px;
  font-weight: 600;
  color: black;
  border: 1px solid #ff3838;
  margin-right: -1px;
  margin-bottom: -1px;
}

.Landing__block__description {
  color: black;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 24px;
  word-wrap: normal;
  width: 1200px;
}

.Landing__block__banner {
  width: 1300px;
  margin-left: -50px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.Landing__block__bottom {
  color: black;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ff3838;
}

.Landing__copyright {
  margin-top: 90px;
  margin-left: 50px;
  font-weight: 900;
  color: black;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.Landing__box {
  margin-top: 75vh;
  padding-top: 5px; 
  padding-bottom: 50px;
  background-color: white;
}

.Landing__header {
  margin-top: 80px;
  font-family: 'Manrope';
  font-weight: 900;
  font-size: 30px;
  padding-right: 50px;
  margin-left: 30px;
}

.Landing__subheader {
  font-family: 'Manrope';
  font-weight: 600;
  font-size: 18px;
  padding-right: 50px;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: 30px;
}


@media screen and (max-width: 1000px) {

    .Landing__header {
      font-size: 20px;
      margin-left: 20px;
    }

    .Landing__subheader {
      font-size: 12px;
      margin-left: 20px;
    }

    .Landing__box {
      margin-top: 55vh;
    }

    .Landing__hello {
      border-top: none;
      font-size: 20px;
      margin-bottom: 200px;
      width: calc(100vw - 50px) !important;
      letter-spacing: -0.6px;
      word-wrap: normal;
      letter-spacing: 0.3px;
      width: 100vw;
      margin-top: 0px;
      padding-top: 0px;
    }

    .Landing__hello__text {
      overflow: hidden;
    }
    .Landing__hello__text--emphasize {
    }

    .Landing__block {
      margin-top: 100px;
      margin-bottom: 100px;
      width: calc(100vw - 40px) !important;
      word-wrap: break-word;
    }

    .Landing__block__name {
      width: 100%;
      color: #ff3838;
      padding-bottom: 15px;
      font-size: 26px;
      font-weight: 700;
      border-bottom: 1px solid #ff3838;
      margin-bottom: 50px;
      width: calc(100vw - 40px) !important;
    }

    .Landing__block__name-hyper {
      margin-top: 50px;
      font-family: 'Dela gothic one';
      font-size: 45px;
      line-height: 43px;
      letter-spacing: -0.1px;
      color: #ff383800;
      -webkit-text-stroke: #ff3838 0.5px;
      -webkit-text-stroke-width: 0.5px;
    }

    .Landing__block__name-hyper:hover {
      color: #ff3838;
      -webkit-text-stroke-width: 0px;
      cursor: pointer;
    }

    .Landing__block__name-small {
      font-size: 22px;
      margin-bottom: 10px;
      border-bottom: none;
      font-weight: 600;
      color: #7892D0;
    }

    .Landing__block__islands {
      padding-top: 10px;
      padding-bottom: 10px;
      overflow: hidden;
      margin-bottom: 50px;
      width: calc(100vw - 40px);
      height: auto;
    }

    .Landing__block__islands__island {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      font-size: 13px;
      letter-spacing: 0.5px;
      font-weight: 700;
      color: black;
      border: 1px solid #ff3838;
      margin-right: -1px;
      margin-bottom: -1px;
      max-width: calc(100vw - 40px);
      word-wrap: normal;
    }

    .Landing__block__description {
      margin-top: -20px;
      color: black;
      font-size: 16px;
      line-height: 15px;
      font-weight: 700;
      letter-spacing: 0.4px;
      line-height: 24px;
      word-wrap: normal;
      width: calc(100vw - 40px);
    }

    .nostroke {
      display: none;
    }

    .Landing__block__banner {
      margin-left: -50px;
      margin-top: 25px;
      margin-bottom: 25px;
      width: calc(100vw + 60px) !important;
    }

    .Landing__block__bottom {
      color: rgb(158, 159, 255);
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.3px;
      padding-bottom: 30px;
      border-bottom: 1px solid #ff3838;
    }
}
