@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');

.Block {
    height: 300px;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100vw - 20px);
    margin-top: 20px;
    vertical-align: top;
    display: inline-block;
    overflow: hidden;
    position: relative;
    -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 4s; /* Firefox < 16 */
        -ms-animation: fadein 4s; /* Internet Explorer */
         -o-animation: fadein 4s; /* Opera < 12.1 */
            animation: fadein 4s;
}

.Block__image {
    pointer-events: none;
    height: 298px;
    width: calc(100% - 2px);
    object-fit: cover;
    border-radius: 10px;
}

.Blocks {
    top: 0px;
    left: 0px;
    width: 100%;
    margin-top: 20px;
}

.Block__expand {
    display: block;
    float: right;
    color: #2725258f;
    font-family: 'Material Icons Outlined';
    width: 50px;
    height: 50px;
    z-index: 10000;
    position: relative;
    background: transparent;
    border: none;
    font-size: 30px;
    margin-right: 20px;
    margin-top: -40px;
    border: none;
    background: none;
}

.Block__link {
    position: absolute;
    right: 20px;
    top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-left: 30px;
    margin-top: 10px;
    font-family: 'Namu';
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    backdrop-filter: blur(20px);
    border: 1px solid #2725258f;
    color: black;
    transition: 0.5s all;
}

.Block__link:hover {
    background-color: black;
    color: white;
    transition: 0.5s all;
}


.Block__text {
    pointer-events: none;
    color: black;
    width: 50%;
    margin-left: 30px;
    margin-top: -270px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    font-family: 'Manrope';
    letter-spacing: 0px;
}

.Block__text--hidden {
    font-size: 14px;
    pointer-events: none;
    color: rgb(56, 56, 56);
    width: 80%;
    margin-left: 25px;
    margin-top: 7px;
    font-weight: 500;
    font-family: 'Namu';
}

.CopyrightBlock {
    margin-top: 70px;
    margin-left: 20px;
    color: #6d6d6d;
    font-size: 15px;
    font-weight: 800;
}

@media screen and (min-width: 600px) {

    .Block__expand {
        display: none;
    }

    .Block__text {
        font-size: 16px;
        margin-top: -450px;
        font-size: 25px;
        line-height: 29px;
        font-weight: 600;
        margin-left: 45px;
        position: relative;
        max-width: 50%;
        letter-spacing: 0px;
        font-family: 'Namu';
    }

    .Block__text--hidden {
        display: none;
        margin-left: 25px;
    }

    .Block__link {
        position: absolute;
        right: 50px;
        top: 50px;
        margin-top: 0px;
        font-size: 18px;
        margin-left: 45px;
        padding-left: 15px;
        padding-right: 15px;
        color: #2b2424;
        border: 1px solid #443c3c81;
        font-weight: 500;
        font-family: 'Namu';
    }

    .Block:hover .Block__text--hidden {
        display: block;
        margin-left: 30px;
        margin-top: 30px;
        font-size: 20px;
    }

    .Block__image {
        height: 250px;
        border: 1px solid #ebebeb;
        border-radius: 15px;
    }


    .Blocks {
        margin-top: 40px;
        width: 600px;
        margin-left: calc(50vw - 300px);
    }

    .Block {
        height: 300px;
        margin-left: 0px;
        margin-right: 10px;
        margin-top: -10px;
        width: 290px;
    }
}

.CopyrightBlock__link {
    text-decoration: none;
    color: #cacaca;
}

@media screen and (min-width: 1000px) {


    .Blocks {
        width: 1400px;
        margin-left: calc(50vw - 500px);
    }

    .Block__image {
        height: 480px;
    }

    .Block {
        margin-bottom: 30px;
        margin-right: 20px;
        height: 480px;
        width: 470px;
    }
}

@media screen and (min-width: 1400px) {
    .Blocks {
        width: 1400px;
        margin-left: calc(50vw - 700px);
    }

    .Block {
        width: 670px;
    }
}


@media screen and (min-width: 1800px) {
    .Blocks {
        width: 1800px;
        margin-left: calc(50vw - 900px);
    }

    .Block {
        width: 870px;
    }
}

@media screen and (min-width: 600px) {
    
    .Block1 {
    }

    .Block2 {
    }

    .Block__image1 {

    }

    .Block__image2 {
    }
}