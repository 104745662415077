
.Lander__social {
  white-space: nowrap;
  position: fixed;
  top: 3px;
  z-index: 50001;
  right: 200px;
}

.Lander {
  width: calc(100vw);
  height: calc(100vh);
  border-bottom: 1px solid rgb(201, 201, 201);
  max-height: 1000px;
  margin-top: 5px;
  margin-left: 0px;
  color: black;
  position: relative;
  margin-bottom: -50px;
  overflow: hidden;
  animation: shine 9s linear infinite;
  background-color: #f6f7ff;
  background: linear-gradient(to left, #f6f7ff 20%, #e8ffeb 40%, #ffffaf 60%, #f6f7ff 80%);
  background-size: 200% auto;
}

.Lander__bg {
  position: absolute;
  right: 20px;
  height: 600px;
  bottom: 0px;
}

.Lander__text {
  width: 100%;
  overflow: hidden;
  font-family: 'Sora';
  margin-top: 100px;
  font-size: 38px;
  font-weight: 500;
  animation: reveal 1s ease forwards;
  animation: 
    typing 1.2s steps(40, end),
}

.Lander__text--emphasize {
  font-weight: 700;
}

@keyframes typing {
  from { height: 0 }
  to { height: 100% }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}


.Lander__link {
  margin-top: 30px;
  font-size: 25px;
  font-weight: 900;
  border: 1px solid #ffffff;
  background: none;
  text-decoration: none;
  animation: reveal 0.35s ease forwards;
}

.Lander__link:hover {
  color: #0a6cff;
}

.Lander__inside {
  width: calc(100vw - 265px);
  padding-top: 90px;
  margin-left: 125px;
  margin-right: 140px;
}

.Lander__logo {
  margin-top: 40px;
  margin-left: 65px;
  width: 40px;
  pointer-events: none;
  animation: reveal 0.5s ease forwards;
  filter: invert();
}

.Lander__island {
  font-family: 'Sora';
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 22px;
  color: #080808;
  border: 1px solid black;
  border-radius: 50px;
  margin-right: 50px;
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 20px;
  padding-top: 12px;
  height: 40px;
  margin-bottom: 15px;
  overflow: none;
  letter-spacing: 0.2px;
  background-color: #ffffffcc;
  font-weight: 400;
}

.Lander__click {
  overflow: none;
}


.Lander__island__icon {
  width: 23px;
  overflow: none;
  margin-top: 7px;
  margin-bottom: -3px;
}

.Lander__island__icon:hover {
  fill: gray;
}

.Lander__island:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
}

.Lander__islands {
  position: absolute;
  bottom: 0px;
  margin-bottom: 100px;
}

.Lander__island__smol {
  overflow: none;
  border: none;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  min-width: 30px;
  background: transparent;
}

.Lander__island__smol:hover {
  border: none;
  background: transparent;
}

.Lander__hint {
  color: #363636;
  font-family: 'Chivo';
  font-weight: 800;
  margin-top: 30px;
}


.Element {
  white-space: normal;
  display: inline-block;
  width: 200px;
  height: 290px;
  box-shadow: 0px 0px 20px 5px #4d4d4d1c;
  padding: 5px;
  padding-left: 20px;
  margin-right: 5px;
  margin-bottom: 50px;
  border-radius: 5px;
  width: calc(25vw - 85px);
  vertical-align: top;
  margin-right: 10px;
  background-color: white;
}

.Element:hover {
}

.Element:hover .Element__icon {
}

.Element__text {
  font-size: 22px;
  font-weight: 600;
}

.Element__hint {
  margin-top: 10px;
  font-family: 'Manrope';
  font-size: 13px;
  margin-right: 20px;
}

.Element__icon {
  margin-left: 0px;
  pointer-events: none;
}

.Element__icon__img {
  margin-left: 0px;
  margin-top: 20px;
  height: 150px;
}


.Heading {
  letter-spacing: -0.3px;
  color: #080808;
  font-weight: 500;
  font-size: 25px;
  margin-top: 200px;
  margin-left: 100px;
  margin-bottom: 230px;
  margin-right: 20px;
  position: relative;
  color:white;
}

.Heading--invert {
  font-family: 'Syne';
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 40px;
  margin-left: 130px;
  color:black;
}

.Heading--smol {
  margin-top: 10px;
  margin-bottom: 80px;
  padding-left: 30px;
  color:#9b9b9b;
}

.Heading__link {
  color: #7da0ff;
  text-decoration: none;
}

.Heading__link:hover {
  text-decoration: underline;
}

.Heading--footer {
  margin-left: 130px;
  padding-top: 40px;
  font-family: 'Sora';
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Footer__action {
  margin-top: 20px;
  margin-left: 130px;
  margin-bottom: 150px;
  height: 50px;
  width: 200px;
  background: none;
  color: #151f64;
  border: 1px solid #151f64;
  background-color: white;
  box-shadow: none;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Sora';
}

.Footer__action:hover {
  border: 1px solid white;
  color: white;
  background-color: #151f64;
  cursor: pointer;
}

.Copyright {
  font-family: 'Sora';
  font-weight: 500;
  font-size: 20px;
  margin-left: 130px;
}

.Container {
  width: 100vw;
}

.Loader__social__text {
  bottom: 0px;
  padding-top: 150px;
  width: 100px;
  font-weight: 700;
  font-size: 15px;
  vertical-align: center;
}


@keyframes move {
  from {
    transform: translate(0px, 0px);
  }

  to { 
    transform: translate(-10px, 0px);
  }
}

@keyframes zoom {
  from {
    transform: scale(1);
    filter: blur(0px);
    -webkit-filter: blur(0px);
    opacity: 1;
  }

  to { 
    transform: scale(1.1);
    filter: blur(8px);
    -webkit-filter: blur(3px);
    opacity: 0.1;
  }
}


@media screen and (max-width: 1200px) {

  .Lander__inside {
    width: calc(100vw - 320px);
    margin-left: 120px;
    margin-right: 200px;
    margin-top: -80px;
  }
}

@media screen and (max-width: 900px) {
  * {
    overflow-x: hidden;
  }

  .Element {
    height: 280px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: calc(100vw - 65px);
    margin-right: 15px;
    letter-spacing: 0.2px;
  }

  .Element__icon {
    margin-top: -8px;
  }

  .Element__hint {
    font-size: 16px;
  }

  .Lander__islands {
    position: relative;
    margin-top: -300px;
    margin-bottom: -349px;
  }

  .Lander__island {
    border: 1px solid black;
    width: calc(100vw - 48px);
    border-radius: 40px;
    font-size: 18px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 1px;
    font-family: 'Poppins';
  }

  .Lander__island:hover {
    border: 1px solid gray;
  }

  .Lander__island__smol {
    text-align: left;
    padding-left: 10px;
    width: 40px;
    height: 40px;
    margin-right: 6px;
    border: none;
  }
  
  .Lander__island__smol:hover {
    border: none;
  }


  .Lander {
    height: 500px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    background-size: 100%;
    background: linear-gradient(to top,#e9ffeccc 0%, #ffffe0cc 60%, #ffe7fbcc 80%);
  }

  .Lander__bg {
    height: 300px;
  }

  .Lander__text {
    width: 100%;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 300;
    margin-right: 20px;
    margin-bottom: 340px;
    font-weight: 400;
    line-height: 40px;
    overflow: hidden;
    letter-spacing: 0px;
    padding-top: 60px;
  }

  .Lander__social {
    position: absolute;
    margin-top: 50px;
    margin-right: 0px;
    left: 15px;
    top: 350px;
    margin-bottom: 25px;
    z-index: 1;
  }

  .Lander__island__icon {
    width: 30px;
  }

  .Lander__island--left {
    border-top-left-radius: 9px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 0px;
    margin-right: 0px;
  }

  .Lander__island--right {
    margin-left: -1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 9px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 9px;
  }

  .Lander__logo {
    margin-top: 40px;
    margin-left: 25px;
    width: 40px;
    pointer-events: none;
    animation: reveal 0.35s ease forwards;
    filter: invert();
  }

  .Lander__inside {
    width: calc(100vw - 40px);
    left: 0px;
    margin-left: 20px;
    margin-right: 20px;
    height: 1200px;
  }

  .Copyright {
    margin-left: 30px;
  }

  .Heading--footer {
    font-weight: 500;
    border: none;
    margin-left: 15px;
    font-size: 30px;
    text-align: center;
  }

  .Heading--invert {
    font-size: 27px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 20px;
    letter-spacing: -0.6px;
    font-weight: 600;
  }

  .Footer__action {
    width: calc(100vw - 60px);
    margin-left: 30px;
    border-radius: 15px;
    height: 60px;
    border: 1px solid #151f64;
    color: #151f64;
    background-color: white;
    margin-bottom: 100px;
  }

  .Heading--smol {
    color: #6d6d6d;
    font-size: 17px;
    letter-spacing: 0.2px;
    line-height: 30px;
    margin-top: 20px;
    margin-left: -10px;
    font-weight: 500;
    margin-bottom: 20px;
  }

}

@media screen and (min-width: 1800px) {

  .Element {
    width: calc(393px);
  }
  .Lander__inside {
    width: 1800px;
    margin-left: calc(50vw - 900px);
  }

  .Container {
    width: 1800px;
    margin-left: calc(50vw - 900px);
  }

  .Card {
    width: 850px;
    height: 850px;
  }

  .Card--insane {
    width: 1725px;
  }

  .Heading {
    margin-left: -20px;
    left: 0px;
  }

  .Heading--invert {
    margin-left: 8px;
    left: 0px;
  }

  .Heading--footer {
    margin-left: 0px;
    margin-bottom: 50px;
  }

  .Footer__action {
    margin-left: 0px;
  }

  .Copyright {
    margin-left: 0px;
  }
}