@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.Header {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 60px;
    width: 100vw;
    z-index: 100000;
    transition: height 0.4s, background-color 0.4s;
    white-space: nowrap;
}

.Header--open {
    height: 100vh;
    background-color: white !important;
    transition: height 0.4s, background-color 0.4s;
}

.Header__menu__button__text {
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
    margin-right: -15px !important;
    font-size: 18px;
    letter-spacing: -0.2px;
    font-weight: 500;
    margin-right: 5px;
    font-family: 'Manrope';
}

.Header__logo {
    position: relative;
    margin-top: 40px;
    text-align: left;
    padding-left: 40px;
    font-family: 'Poppins';
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    fill: white;
    mix-blend-mode: difference;
    font-size: 36px;
    transform: font-size 0.4s;
    letter-spacing: -1.5px;
    z-index: 10000001;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
}

.Header__menu__button {
    text-align: right;
    border: none;
    background: none;
    color: black;
    margin-top: 14px;
    padding-right: 40px;
    cursor: pointer;
    width: 100vw;
    position: fixed;
    top: 0px;
    right: 0px;
}

.Header__menu__button:hover {
    opacity: 0.8;
}

.Header__menu__text {
    pointer-events: none;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 15px;
    margin-top: 6px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: top;
}

.Header__menu__icon {
    pointer-events: none;
    display: inline-block;
    vertical-align: top;
    font-weight: 100;
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.4s;
    margin-top: 0px;
    font-size: 26px !important;
    font-family: 'Material Icons Outlined';
}


.Header__logo--smaller {
    margin-top: 8px;
    font-size: 28px;
    transform: font-size 0.4s;
}

.Header__info {
    color: black;
    font-family: 'Manrope';
    font-size: 40px;
    top: 250px;
    margin-left: 150px;
}

.Header__info__title {
    display: inline-block;
    margin-right: 50px;
    font-weight: 400;
    width: 150px;
    font-size: 30px;
    text-decoration: double;
    color: #c0c0c0;
    text-transform: lowercase;
}

.Header__info__value {
    display: inline-block;
    font-weight: 700;
    font-size: 30px;
    text-decoration: unset;
    color: black;
}

.Header__info__box {
    display: block;
    margin-top: 0px;
    text-decoration: none;
}

.Header__info__box:hover {
    opacity: 0.75;
}

.Header__info__hello {
    color: #a8a8a8;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    font-family: 'Manrope';
}

@media screen and (max-width: 1000px) {   


    .Header__info__hello {
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 20px;
        word-wrap: normal;
    }

    .Header__info__title {
        display: block;
        font-size: 15px;
    }
    
    .Header__info__value {
        display: block;
        font-size: 18px;
    }

    .Header__info__box {
        margin-top: 25px;
    }
    
    .Header__info {
        margin-left: 20px;
        font-size: 30px;
        padding-bottom: 25px;
        top: 75px;
        width: calc(100vw - 40px);
    }

    .Header__logo {
        margin-top: 12px;
        margin-left: -5px; 
        font-size: 28px;
        padding-left: 20px;
    }   

    .Header__menu__button {
        padding-top: 5px;
        padding-right: 20px;
    }

    .Header__logo--smaller {
        font-size: 26px;
    }

    .Header__menu__text {
        display: none;
    }

    .Header__menu__button__text {
        margin-top: 8px;
        font-size: 13px;
        margin-right: -1px !important;
    }
}