@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Jost:wght@300&display=swap');
.Hyperlapse {
    height: 75vh;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: -1;
    background-color: white;
    margin-bottom: 0px;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; transform: translateY(-20px);}
    to   { opacity: 1; transform: translateY(0px); }
}

.Hyperlapse__position {
    border: none;
    background: none;
    margin-bottom: 3px;
    opacity: 0.4;
    font-weight: 800;
    font-size: 10px;
    text-align: left;
    cursor: pointer;
    margin-left: -5px;
}

.Hyperlapse__position:hover {
    opacity: 0.6;
}

.Hyperlapse__scroll {
    height: 75vh;
    width: 100px;
    position: absolute;
    top: 0px;
    left: 0px;
    border: none;
    background: none;
    text-align: center;
    color: transparent;
    background-color: transparent;
    padding-bottom: 30px;
    cursor: pointer;
    font-size: 30px;
    opacity: 0;
    transform: opacity 0.4s;
    mix-blend-mode: overlay;
}

.Hyperlapse__scroll--left {
    padding-left: 25px;
    background-color: transparent;
    /*background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);*/
}

.Hyperlapse__scroll--right {
    left: calc(100vw - 100px);
    right: 0px;
    background-color: transparent;
}

.Hyperlapse__scroll:hover {
    color: black;
    opacity: 1;
    transform: opacity 0.4s ease-in-out;
    background-color: transparent;
}

.Hyperlapse__hyper {
    position: relative;
    height: 75vh;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    vertical-align: top;
    width: 100vw;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Hyperlapse__hyper:hover .Hyperlapse__title {
    text-decoration: underline;
}

.Hyperlapse__container {
    transition: margin-left 2s ease-in-out;
}

.Hyperlapse__image {
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 90vh;
    object-fit: cover;
    opacity: 1;
    transition: transform 1.5s;
    transform: scale(1);
}

.Hyperlapse__image:hover {
    transition: transform 1.5s;
    transform: scale(1.05);
}

.Hyperlapse__info {
    left: 3vw;
    position: absolute;
    bottom: 30px;
    pointer-events: none;
}

.Hyperlapse__action {
    font-family: 'Namu';
    font-weight: 600;
    font-size: 15px;
    opacity: 0.6;
    margin-bottom: 15px;
}

.Hyperlapse__title {
    font-family: 'Namu';
    font-weight: 800;
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 3px;
}

.Hyperlapse__subtitle {
    margin-top: -5px;
    font-family: 'Namu';
    font-weight: 600;
    font-size: 25px;
}

@media screen and (max-width: 1000px) {

    .Hyperlapse {
        pointer-events: all;
        height: 55vh;
        opacity: 1;
        z-index: 10;
        position: absolute;
        overflow-y: hidden;
    }

    .Hyperlapse__hyper {
        height: 55vh;
        overflow-y: hidden;
    }

    .Hyperlapse__scroll {
        display: none;
        height: 55vh;
        background-color: transparent;
    }

    .Hyperlapse__scroll--left {
        position: absolute;
        z-index: 100000;
        left: 0px;
        width: 15px;
        top: 0px;
        margin-left: 0px;
        display: none;
        background-color: transparent;
    }

    .Hyperlapse__scroll--right {
        position: absolute;
        z-index: 100000;
        right: 0px;
        top: 0px;
        width: 15px;
        margin-right: 0px;
        display: none;
        background-color: transparent;
    }

    .Hyperlapse__info {
        width: calc(100% - 30px);
        margin-right: 15px;
        left: 15px;
        margin-bottom: -30px;
    }

    .Hyperlapse__position {
        position: absolute;
        right: 0px;
        bottom: 0px;
        opacity: 1;
    }

    .Hyperlapse__image {
        margin-top: 15vh;
        width: 101vw;
        right: -5px;
        height: 55vh;
    }

    .Hyperlapse__action {
        font-size: 15px;
        margin-bottom: 10px;
        padding: 5px;
    }

    .Hyperlapse__title {
        font-size: 18px;
        line-height: 24px;
        margin-right: 10px;
    }

    .Hyperlapse__subtitle {
        margin-top: 0px;
        font-size: 18px;
        margin-right: 10px;
        margin-bottom: 5px;
    }

    .Hyperlapse__scroll {
        display: none;
        position: absolute;
        background-color: red;
        z-index: 10000;
    }
}

.Hyperlapse__hyper--intro {
    background-color: #ffffff;
    text-align: center;
    margin-top: 0px;
    padding-top: 100px;
}

.Hyperlapse__in__title {
    margin-top: 100px;
    color: #292929;
    font-size: 60px;
    width: 650px;
    margin-left: calc(50vw - 300px);
    font-family: 'Namu';
}

.Hyperlapse__in__subtitle {
    color: #808080;
    font-family: 'Namu';
    margin: 30px;
}

@media screen and (max-width: 1000px) {
    .Hyperlapse__hyper--intro {
        padding-top: 100px;
    }

    .Hyperlapse__in__title {
        margin-top: 50px;
        color: #292929;
        font-size: 30px;
        width: calc(100vw - 40px);
        margin-left: 20px;
    }
    
}

.Hyperlapse__introtitle {
    position: absolute;
    bottom: 250px;
    color: #ebebeb;
    font-weight: 600;
    font-size: 65px;
    letter-spacing: -1.5px;
    margin-left: 90px;
    margin-right: 100px;
    max-width: 800px;
    line-height: 70px;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    animation: 1s ease-in just-grow;
    font-family: 'Namu';
    transition: opacity 1.5s .5s cubic-bezier(.19,1,.22,1),transform 1.5s .5s cubic-bezier(.19,1,.22,1),-webkit-transform 1.5s .5s cubic-bezier(.19,1,.22,1)
}

@keyframes grow-left {
    from {
        margin-left: -100%;
        transform: scaleX(-1);
        opacity: 0;
    }
    to {
        margin-left: 90px;
        transform: scaleX(1);
        opacity: 1;
    }
  }

.Hyperlapse__introsubtitle {
    position: absolute;
    bottom: 170px;
    color: #ebebeb;
    font-weight: 600;
    opacity: 1;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-left: 90px;
    margin-right: 100px;
    max-width: 600px;
    animation: 1.5s ease-in just-grow;
}


@keyframes just-grow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }

.Hyperlapse__introbutton {
    position: absolute;
    bottom: 70px;
    color: #ebebeb;
    font-weight: 600;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-right: 20px;
    background-color: transparent;
    margin-left: 84px;
    font-family: 'Namu';
    font-size: 15px;
    font-weight: 600;
    box-shadow: none;
    border: none;
    max-width: 800px;
    padding-left: 0px;
    animation: 1s ease-in-out grow-bottom;
    animation: 2s 2s ease-in-out wiggle;
    border: 1px solid #ebebeb;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 30px;
}

@keyframes grow-bottom {
    0% {
        padding-top: 70px;
    }
    100% {
        padding-top: 0px;
    }
}

@keyframes wiggle {
    0% {
        padding-left: 0px;
    }
    50% {
        padding-left: 20px;
    }
    100% {
        padding-left: 0px;
    }
  }

.Hyperlapse__introbutton:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media screen and (max-width: 1000px) {
    .Hyperlapse__introtitle {
        position: absolute;
        bottom: 190px;
        color: white;
        font-size: 40px;
        line-height: 39px;
        font-weight: 600;
        letter-spacing: -0.5px;
        margin-left: 20px;
        margin-right: 20px;
        overflow: hidden;
    }
    
    .Hyperlapse__introsubtitle {
        position: absolute;
        bottom: 120px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
    }    

    .Hyperlapse__introbutton {
        position: absolute;
        bottom: 30px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: transparent;
        padding: 12px;
    }
}

@media screen and (max-width: 350px) {
    .Hyperlapse__introtitle {
        position: absolute;
        bottom: 150px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.5px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .Hyperlapse__introsubtitle {
        position: absolute;
        bottom: 80px;
        color: white;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
    }    

    .Hyperlapse__introbutton {
        position: absolute;
        bottom: 30px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: transparent;
    }
}