.Hamburger {
    border: none;
    background: none;
    width: 40px;
    height: 40px;
    margin-top: -10px;
}

.Hamburger__button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    /* border: 3px solid #fff; */
}

.Hamburger__button__lines {
    width: 18px;
    height: 2px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
}

.Hamburger__button__lines::before,
.Hamburger__button__lines::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 2px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
}

.Hamburger__button__lines::before {
    transform: translateY(-6px);
}

.Hamburger__button__lines::after {
    transform: translateY(6px);
}

 .Hamburger__button.open .Hamburger__button__lines {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
 .Hamburger__button.open .menu-hamburger__button__lines::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .Hamburger__button--open .menu-hamburger__button__lines::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }