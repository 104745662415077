.Product {
    padding-top: 100px;
    height: 90vh;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: -1;
    background-color: black;
    text-align: center;
}

.Product__name {
    margin-top: 50px;
}

.Product__image {
    top: 20px;
    width: 70vw;
}

@media screen and (max-width: 1000px) {
    .Product {
        pointer-events: all;
        height: 75vh;
        opacity: 1;
        z-index: 10;
        overflow-y: hidden;
    }
}