
@font-face {
    font-family: 'Namu';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts//NAMU-Pro.eot');
    src: url('../fonts//NAMU-Pro.woff');
    src: url('../fonts//NAMU-Pro.woff2');
    src: url('../fonts//NAMU-Pro.otf');
    src: url('../fonts//NAMU-Pro.ttf');
}
  