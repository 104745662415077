@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Palanquin+Dark:wght@400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chivo:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Jost:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@charset "UTF-8";
body {
  width: 100vw;
  white-space: normal;
  color: #080808;
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 50px;
  background-color:#ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes reveal {
  from {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px);
    -webkit-transform: rotate3d(1, 0, 0, 45deg);
            transform: rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }

  to { 
    -webkit-filter: blur(0); 
            filter: blur(0);
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    -webkit-transform: rotate3d(0, 0, 0, 0deg);
            transform: rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }
}

@keyframes reveal {
  from {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    -webkit-transform: translate(0px, 40px);
            transform: translate(0px, 40px);
    -webkit-transform: rotate3d(1, 0, 0, 45deg);
            transform: rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }

  to { 
    -webkit-filter: blur(0); 
            filter: blur(0);
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    -webkit-transform: rotate3d(0, 0, 0, 0deg);
            transform: rotate3d(0, 0, 0, 0deg);
    opacity: 1;
  }
}

.rotated {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}



.Lander__social {
  white-space: nowrap;
  position: fixed;
  top: 3px;
  z-index: 50001;
  right: 200px;
}

.Lander {
  width: calc(100vw);
  height: calc(100vh);
  border-bottom: 1px solid rgb(201, 201, 201);
  max-height: 1000px;
  margin-top: 5px;
  margin-left: 0px;
  color: black;
  position: relative;
  margin-bottom: -50px;
  overflow: hidden;
  -webkit-animation: shine 9s linear infinite;
          animation: shine 9s linear infinite;
  background-color: #f6f7ff;
  background: linear-gradient(to left, #f6f7ff 20%, #e8ffeb 40%, #ffffaf 60%, #f6f7ff 80%);
  background-size: 200% auto;
}

.Lander__bg {
  position: absolute;
  right: 20px;
  height: 600px;
  bottom: 0px;
}

.Lander__text {
  width: 100%;
  overflow: hidden;
  font-family: 'Sora';
  margin-top: 100px;
  font-size: 38px;
  font-weight: 500;
  -webkit-animation: reveal 1s ease forwards;
          animation: reveal 1s ease forwards;
  -webkit-animation: 
    typing 1.2s steps(40, end),;
          animation: 
    typing 1.2s steps(40, end),
}

.Lander__text--emphasize {
  font-weight: 700;
}

@-webkit-keyframes typing {
  from { height: 0 }
  to { height: 100% }
}

@keyframes typing {
  from { height: 0 }
  to { height: 100% }
}

@-webkit-keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}


.Lander__link {
  margin-top: 30px;
  font-size: 25px;
  font-weight: 900;
  border: 1px solid #ffffff;
  background: none;
  text-decoration: none;
  -webkit-animation: reveal 0.35s ease forwards;
          animation: reveal 0.35s ease forwards;
}

.Lander__link:hover {
  color: #0a6cff;
}

.Lander__inside {
  width: calc(100vw - 265px);
  padding-top: 90px;
  margin-left: 125px;
  margin-right: 140px;
}

.Lander__logo {
  margin-top: 40px;
  margin-left: 65px;
  width: 40px;
  pointer-events: none;
  -webkit-animation: reveal 0.5s ease forwards;
          animation: reveal 0.5s ease forwards;
  -webkit-filter: invert();
          filter: invert();
}

.Lander__island {
  font-family: 'Sora';
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 22px;
  color: #080808;
  border: 1px solid black;
  border-radius: 50px;
  margin-right: 50px;
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 20px;
  padding-top: 12px;
  height: 40px;
  margin-bottom: 15px;
  overflow: none;
  letter-spacing: 0.2px;
  background-color: #ffffffcc;
  font-weight: 400;
}

.Lander__click {
  overflow: none;
}


.Lander__island__icon {
  width: 23px;
  overflow: none;
  margin-top: 7px;
  margin-bottom: -3px;
}

.Lander__island__icon:hover {
  fill: gray;
}

.Lander__island:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
}

.Lander__islands {
  position: absolute;
  bottom: 0px;
  margin-bottom: 100px;
}

.Lander__island__smol {
  overflow: none;
  border: none;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  min-width: 30px;
  background: transparent;
}

.Lander__island__smol:hover {
  border: none;
  background: transparent;
}

.Lander__hint {
  color: #363636;
  font-family: 'Chivo';
  font-weight: 800;
  margin-top: 30px;
}


.Element {
  white-space: normal;
  display: inline-block;
  width: 200px;
  height: 290px;
  box-shadow: 0px 0px 20px 5px #4d4d4d1c;
  padding: 5px;
  padding-left: 20px;
  margin-right: 5px;
  margin-bottom: 50px;
  border-radius: 5px;
  width: calc(25vw - 85px);
  vertical-align: top;
  margin-right: 10px;
  background-color: white;
}

.Element:hover {
}

.Element:hover .Element__icon {
}

.Element__text {
  font-size: 22px;
  font-weight: 600;
}

.Element__hint {
  margin-top: 10px;
  font-family: 'Manrope';
  font-size: 13px;
  margin-right: 20px;
}

.Element__icon {
  margin-left: 0px;
  pointer-events: none;
}

.Element__icon__img {
  margin-left: 0px;
  margin-top: 20px;
  height: 150px;
}


.Heading {
  letter-spacing: -0.3px;
  color: #080808;
  font-weight: 500;
  font-size: 25px;
  margin-top: 200px;
  margin-left: 100px;
  margin-bottom: 230px;
  margin-right: 20px;
  position: relative;
  color:white;
}

.Heading--invert {
  font-family: 'Syne';
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 40px;
  margin-left: 130px;
  color:black;
}

.Heading--smol {
  margin-top: 10px;
  margin-bottom: 80px;
  padding-left: 30px;
  color:#9b9b9b;
}

.Heading__link {
  color: #7da0ff;
  text-decoration: none;
}

.Heading__link:hover {
  text-decoration: underline;
}

.Heading--footer {
  margin-left: 130px;
  padding-top: 40px;
  font-family: 'Sora';
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Footer__action {
  margin-top: 20px;
  margin-left: 130px;
  margin-bottom: 150px;
  height: 50px;
  width: 200px;
  background: none;
  color: #151f64;
  border: 1px solid #151f64;
  background-color: white;
  box-shadow: none;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Sora';
}

.Footer__action:hover {
  border: 1px solid white;
  color: white;
  background-color: #151f64;
  cursor: pointer;
}

.Copyright {
  font-family: 'Sora';
  font-weight: 500;
  font-size: 20px;
  margin-left: 130px;
}

.Container {
  width: 100vw;
}

.Loader__social__text {
  bottom: 0px;
  padding-top: 150px;
  width: 100px;
  font-weight: 700;
  font-size: 15px;
  vertical-align: center;
}


@-webkit-keyframes move {
  from {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }

  to { 
    -webkit-transform: translate(-10px, 0px); 
            transform: translate(-10px, 0px);
  }
}


@keyframes move {
  from {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }

  to { 
    -webkit-transform: translate(-10px, 0px); 
            transform: translate(-10px, 0px);
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    filter: blur(0px);
    -webkit-filter: blur(0px);
    opacity: 1;
  }

  to { 
    -webkit-transform: scale(1.1); 
            transform: scale(1.1);
    filter: blur(8px);
    -webkit-filter: blur(3px);
    opacity: 0.1;
  }
}

@keyframes zoom {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    filter: blur(0px);
    -webkit-filter: blur(0px);
    opacity: 1;
  }

  to { 
    -webkit-transform: scale(1.1); 
            transform: scale(1.1);
    filter: blur(8px);
    -webkit-filter: blur(3px);
    opacity: 0.1;
  }
}


@media screen and (max-width: 1200px) {

  .Lander__inside {
    width: calc(100vw - 320px);
    margin-left: 120px;
    margin-right: 200px;
    margin-top: -80px;
  }
}

@media screen and (max-width: 900px) {
  * {
    overflow-x: hidden;
  }

  .Element {
    height: 280px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: calc(100vw - 65px);
    margin-right: 15px;
    letter-spacing: 0.2px;
  }

  .Element__icon {
    margin-top: -8px;
  }

  .Element__hint {
    font-size: 16px;
  }

  .Lander__islands {
    position: relative;
    margin-top: -300px;
    margin-bottom: -349px;
  }

  .Lander__island {
    border: 1px solid black;
    width: calc(100vw - 48px);
    border-radius: 40px;
    font-size: 18px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 1px;
    font-family: 'Poppins';
  }

  .Lander__island:hover {
    border: 1px solid gray;
  }

  .Lander__island__smol {
    text-align: left;
    padding-left: 10px;
    width: 40px;
    height: 40px;
    margin-right: 6px;
    border: none;
  }
  
  .Lander__island__smol:hover {
    border: none;
  }


  .Lander {
    height: 500px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    background-size: 100%;
    background: linear-gradient(to top,#e9ffeccc 0%, #ffffe0cc 60%, #ffe7fbcc 80%);
  }

  .Lander__bg {
    height: 300px;
  }

  .Lander__text {
    width: 100%;
    margin-top: 20px;
    font-size: 24px;
    font-weight: 300;
    margin-right: 20px;
    margin-bottom: 340px;
    font-weight: 400;
    line-height: 40px;
    overflow: hidden;
    letter-spacing: 0px;
    padding-top: 60px;
  }

  .Lander__social {
    position: absolute;
    margin-top: 50px;
    margin-right: 0px;
    left: 15px;
    top: 350px;
    margin-bottom: 25px;
    z-index: 1;
  }

  .Lander__island__icon {
    width: 30px;
  }

  .Lander__island--left {
    border-top-left-radius: 9px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 0px;
    margin-right: 0px;
  }

  .Lander__island--right {
    margin-left: -1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 9px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 9px;
  }

  .Lander__logo {
    margin-top: 40px;
    margin-left: 25px;
    width: 40px;
    pointer-events: none;
    -webkit-animation: reveal 0.35s ease forwards;
            animation: reveal 0.35s ease forwards;
    -webkit-filter: invert();
            filter: invert();
  }

  .Lander__inside {
    width: calc(100vw - 40px);
    left: 0px;
    margin-left: 20px;
    margin-right: 20px;
    height: 1200px;
  }

  .Copyright {
    margin-left: 30px;
  }

  .Heading--footer {
    font-weight: 500;
    border: none;
    margin-left: 15px;
    font-size: 30px;
    text-align: center;
  }

  .Heading--invert {
    font-size: 27px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 20px;
    letter-spacing: -0.6px;
    font-weight: 600;
  }

  .Footer__action {
    width: calc(100vw - 60px);
    margin-left: 30px;
    border-radius: 15px;
    height: 60px;
    border: 1px solid #151f64;
    color: #151f64;
    background-color: white;
    margin-bottom: 100px;
  }

  .Heading--smol {
    color: #6d6d6d;
    font-size: 17px;
    letter-spacing: 0.2px;
    line-height: 30px;
    margin-top: 20px;
    margin-left: -10px;
    font-weight: 500;
    margin-bottom: 20px;
  }

}

@media screen and (min-width: 1800px) {

  .Element {
    width: calc(393px);
  }
  .Lander__inside {
    width: 1800px;
    margin-left: calc(50vw - 900px);
  }

  .Container {
    width: 1800px;
    margin-left: calc(50vw - 900px);
  }

  .Card {
    width: 850px;
    height: 850px;
  }

  .Card--insane {
    width: 1725px;
  }

  .Heading {
    margin-left: -20px;
    left: 0px;
  }

  .Heading--invert {
    margin-left: 8px;
    left: 0px;
  }

  .Heading--footer {
    margin-left: 0px;
    margin-bottom: 50px;
  }

  .Footer__action {
    margin-left: 0px;
  }

  .Copyright {
    margin-left: 0px;
  }
}


.Landing__hello {
  padding-top: 80px;
  border-top: 1px solid #e0e0e0;
  text-align: center;
  color: rgb(179, 179, 179);
  margin-top: -206px;
  margin-bottom: 300px;
  width: 1200px !important;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

.Landing__hello__text--emphasize {
  font-size: 25px;
  font-weight: 600;
  color: #747474;
  margin-bottom: 40px;
}

.Landing__block {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 1200px !important;
  word-wrap: break-word;
}

.Landing__block__name {
  width: 100%;
  color: #ff3838;
  padding-bottom: 15px;
  font-size: 34px;
  font-weight: 700;
  border-bottom: 1px solid #ff3838;
  margin-bottom: 50px;
}

.Landing__block__name-hyper {
  margin-top: 250px;
  font-family: 'Dela gothic one';
  font-size: 70px;
  color: #ff383800;
  -webkit-text-stroke: #ff3838 0.5px;
  -webkit-text-stroke-width: 0.5px;
}

.Landing__block__name-hyper:hover {
  color: #ff3838;
  -webkit-text-stroke-width: 0px;
  cursor: pointer;
}

.Landing__block__name-small {
  font-size: 22px;
  margin-bottom: 10px;
  border-bottom: none;
  font-weight: 600;
  color: #888888;
}

.Landing__block__islands {
  margin-bottom: 50px;
}

.Landing__block__islands__island {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.8px;
  font-weight: 600;
  color: black;
  border: 1px solid #ff3838;
  margin-right: -1px;
  margin-bottom: -1px;
}

.Landing__block__description {
  color: black;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 24px;
  word-wrap: normal;
  width: 1200px;
}

.Landing__block__banner {
  width: 1300px;
  margin-left: -50px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.Landing__block__bottom {
  color: black;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ff3838;
}

.Landing__copyright {
  margin-top: 90px;
  margin-left: 50px;
  font-weight: 900;
  color: black;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.Landing__box {
  margin-top: 75vh;
  padding-top: 5px; 
  padding-bottom: 50px;
  background-color: white;
}

.Landing__header {
  margin-top: 80px;
  font-family: 'Manrope';
  font-weight: 900;
  font-size: 30px;
  padding-right: 50px;
  margin-left: 30px;
}

.Landing__subheader {
  font-family: 'Manrope';
  font-weight: 600;
  font-size: 18px;
  padding-right: 50px;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: 30px;
}


@media screen and (max-width: 1000px) {

    .Landing__header {
      font-size: 20px;
      margin-left: 20px;
    }

    .Landing__subheader {
      font-size: 12px;
      margin-left: 20px;
    }

    .Landing__box {
      margin-top: 55vh;
    }

    .Landing__hello {
      border-top: none;
      font-size: 20px;
      margin-bottom: 200px;
      width: calc(100vw - 50px) !important;
      letter-spacing: -0.6px;
      word-wrap: normal;
      letter-spacing: 0.3px;
      width: 100vw;
      margin-top: 0px;
      padding-top: 0px;
    }

    .Landing__hello__text {
      overflow: hidden;
    }
    .Landing__hello__text--emphasize {
    }

    .Landing__block {
      margin-top: 100px;
      margin-bottom: 100px;
      width: calc(100vw - 40px) !important;
      word-wrap: break-word;
    }

    .Landing__block__name {
      width: 100%;
      color: #ff3838;
      padding-bottom: 15px;
      font-size: 26px;
      font-weight: 700;
      border-bottom: 1px solid #ff3838;
      margin-bottom: 50px;
      width: calc(100vw - 40px) !important;
    }

    .Landing__block__name-hyper {
      margin-top: 50px;
      font-family: 'Dela gothic one';
      font-size: 45px;
      line-height: 43px;
      letter-spacing: -0.1px;
      color: #ff383800;
      -webkit-text-stroke: #ff3838 0.5px;
      -webkit-text-stroke-width: 0.5px;
    }

    .Landing__block__name-hyper:hover {
      color: #ff3838;
      -webkit-text-stroke-width: 0px;
      cursor: pointer;
    }

    .Landing__block__name-small {
      font-size: 22px;
      margin-bottom: 10px;
      border-bottom: none;
      font-weight: 600;
      color: #7892D0;
    }

    .Landing__block__islands {
      padding-top: 10px;
      padding-bottom: 10px;
      overflow: hidden;
      margin-bottom: 50px;
      width: calc(100vw - 40px);
      height: auto;
    }

    .Landing__block__islands__island {
      padding: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      font-size: 13px;
      letter-spacing: 0.5px;
      font-weight: 700;
      color: black;
      border: 1px solid #ff3838;
      margin-right: -1px;
      margin-bottom: -1px;
      max-width: calc(100vw - 40px);
      word-wrap: normal;
    }

    .Landing__block__description {
      margin-top: -20px;
      color: black;
      font-size: 16px;
      line-height: 15px;
      font-weight: 700;
      letter-spacing: 0.4px;
      line-height: 24px;
      word-wrap: normal;
      width: calc(100vw - 40px);
    }

    .nostroke {
      display: none;
    }

    .Landing__block__banner {
      margin-left: -50px;
      margin-top: 25px;
      margin-bottom: 25px;
      width: calc(100vw + 60px) !important;
    }

    .Landing__block__bottom {
      color: rgb(158, 159, 255);
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.3px;
      padding-bottom: 30px;
      border-bottom: 1px solid #ff3838;
    }
}


@font-face {
    font-family: 'Namu';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/NAMU-Pro.ec1b776e.eot);
    src: url(/static/media/NAMU-Pro.e33eb98b.woff);
    src: url(/static/media/NAMU-Pro.f97ffe28.woff2);
    src: url(/static/media/NAMU-Pro.736fdfdb.otf);
    src: url(/static/media/NAMU-Pro.b30d0e0c.ttf);
}
  
.Hyperlapse {
    height: 75vh;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: -1;
    background-color: white;
    margin-bottom: 0px;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 2s;
}

@-webkit-keyframes fadein {
    from { opacity: 0; -webkit-transform: translateY(-20px); transform: translateY(-20px);}
    to   { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); }
}

@keyframes fadein {
    from { opacity: 0; -webkit-transform: translateY(-20px); transform: translateY(-20px);}
    to   { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); }
}

.Hyperlapse__position {
    border: none;
    background: none;
    margin-bottom: 3px;
    opacity: 0.4;
    font-weight: 800;
    font-size: 10px;
    text-align: left;
    cursor: pointer;
    margin-left: -5px;
}

.Hyperlapse__position:hover {
    opacity: 0.6;
}

.Hyperlapse__scroll {
    height: 75vh;
    width: 100px;
    position: absolute;
    top: 0px;
    left: 0px;
    border: none;
    background: none;
    text-align: center;
    color: transparent;
    background-color: transparent;
    padding-bottom: 30px;
    cursor: pointer;
    font-size: 30px;
    opacity: 0;
    -webkit-transform: opacity 0.4s;
            transform: opacity 0.4s;
    mix-blend-mode: overlay;
}

.Hyperlapse__scroll--left {
    padding-left: 25px;
    background-color: transparent;
    /*background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);*/
}

.Hyperlapse__scroll--right {
    left: calc(100vw - 100px);
    right: 0px;
    background-color: transparent;
}

.Hyperlapse__scroll:hover {
    color: black;
    opacity: 1;
    -webkit-transform: opacity 0.4s ease-in-out;
            transform: opacity 0.4s ease-in-out;
    background-color: transparent;
}

.Hyperlapse__hyper {
    position: relative;
    height: 75vh;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    vertical-align: top;
    width: 100vw;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Hyperlapse__hyper:hover .Hyperlapse__title {
    text-decoration: underline;
}

.Hyperlapse__container {
    transition: margin-left 2s ease-in-out;
}

.Hyperlapse__image {
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 90vh;
    object-fit: cover;
    opacity: 1;
    transition: -webkit-transform 1.5s;
    transition: transform 1.5s;
    transition: transform 1.5s, -webkit-transform 1.5s;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.Hyperlapse__image:hover {
    transition: -webkit-transform 1.5s;
    transition: transform 1.5s;
    transition: transform 1.5s, -webkit-transform 1.5s;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.Hyperlapse__info {
    left: 3vw;
    position: absolute;
    bottom: 30px;
    pointer-events: none;
}

.Hyperlapse__action {
    font-family: 'Namu';
    font-weight: 600;
    font-size: 15px;
    opacity: 0.6;
    margin-bottom: 15px;
}

.Hyperlapse__title {
    font-family: 'Namu';
    font-weight: 800;
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 3px;
}

.Hyperlapse__subtitle {
    margin-top: -5px;
    font-family: 'Namu';
    font-weight: 600;
    font-size: 25px;
}

@media screen and (max-width: 1000px) {

    .Hyperlapse {
        pointer-events: all;
        height: 55vh;
        opacity: 1;
        z-index: 10;
        position: absolute;
        overflow-y: hidden;
    }

    .Hyperlapse__hyper {
        height: 55vh;
        overflow-y: hidden;
    }

    .Hyperlapse__scroll {
        display: none;
        height: 55vh;
        background-color: transparent;
    }

    .Hyperlapse__scroll--left {
        position: absolute;
        z-index: 100000;
        left: 0px;
        width: 15px;
        top: 0px;
        margin-left: 0px;
        display: none;
        background-color: transparent;
    }

    .Hyperlapse__scroll--right {
        position: absolute;
        z-index: 100000;
        right: 0px;
        top: 0px;
        width: 15px;
        margin-right: 0px;
        display: none;
        background-color: transparent;
    }

    .Hyperlapse__info {
        width: calc(100% - 30px);
        margin-right: 15px;
        left: 15px;
        margin-bottom: -30px;
    }

    .Hyperlapse__position {
        position: absolute;
        right: 0px;
        bottom: 0px;
        opacity: 1;
    }

    .Hyperlapse__image {
        margin-top: 15vh;
        width: 101vw;
        right: -5px;
        height: 55vh;
    }

    .Hyperlapse__action {
        font-size: 15px;
        margin-bottom: 10px;
        padding: 5px;
    }

    .Hyperlapse__title {
        font-size: 18px;
        line-height: 24px;
        margin-right: 10px;
    }

    .Hyperlapse__subtitle {
        margin-top: 0px;
        font-size: 18px;
        margin-right: 10px;
        margin-bottom: 5px;
    }

    .Hyperlapse__scroll {
        display: none;
        position: absolute;
        background-color: red;
        z-index: 10000;
    }
}

.Hyperlapse__hyper--intro {
    background-color: #ffffff;
    text-align: center;
    margin-top: 0px;
    padding-top: 100px;
}

.Hyperlapse__in__title {
    margin-top: 100px;
    color: #292929;
    font-size: 60px;
    width: 650px;
    margin-left: calc(50vw - 300px);
    font-family: 'Namu';
}

.Hyperlapse__in__subtitle {
    color: #808080;
    font-family: 'Namu';
    margin: 30px;
}

@media screen and (max-width: 1000px) {
    .Hyperlapse__hyper--intro {
        padding-top: 100px;
    }

    .Hyperlapse__in__title {
        margin-top: 50px;
        color: #292929;
        font-size: 30px;
        width: calc(100vw - 40px);
        margin-left: 20px;
    }
    
}

.Hyperlapse__introtitle {
    position: absolute;
    bottom: 250px;
    color: #ebebeb;
    font-weight: 600;
    font-size: 65px;
    letter-spacing: -1.5px;
    margin-left: 90px;
    margin-right: 100px;
    max-width: 800px;
    line-height: 70px;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-animation: 1s ease-in just-grow;
            animation: 1s ease-in just-grow;
    font-family: 'Namu';
    transition: opacity 1.5s .5s cubic-bezier(.19,1,.22,1),transform 1.5s .5s cubic-bezier(.19,1,.22,1),-webkit-transform 1.5s .5s cubic-bezier(.19,1,.22,1)
}

@-webkit-keyframes grow-left {
    from {
        margin-left: -100%;
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
        opacity: 0;
    }
    to {
        margin-left: 90px;
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
        opacity: 1;
    }
  }

@keyframes grow-left {
    from {
        margin-left: -100%;
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
        opacity: 0;
    }
    to {
        margin-left: 90px;
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
        opacity: 1;
    }
  }

.Hyperlapse__introsubtitle {
    position: absolute;
    bottom: 170px;
    color: #ebebeb;
    font-weight: 600;
    opacity: 1;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-left: 90px;
    margin-right: 100px;
    max-width: 600px;
    -webkit-animation: 1.5s ease-in just-grow;
            animation: 1.5s ease-in just-grow;
}


@-webkit-keyframes just-grow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }


@keyframes just-grow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }

.Hyperlapse__introbutton {
    position: absolute;
    bottom: 70px;
    color: #ebebeb;
    font-weight: 600;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-right: 20px;
    background-color: transparent;
    margin-left: 84px;
    font-family: 'Namu';
    font-size: 15px;
    font-weight: 600;
    box-shadow: none;
    border: none;
    max-width: 800px;
    padding-left: 0px;
    -webkit-animation: 1s ease-in-out grow-bottom;
            animation: 1s ease-in-out grow-bottom;
    -webkit-animation: 2s 2s ease-in-out wiggle;
            animation: 2s 2s ease-in-out wiggle;
    border: 1px solid #ebebeb;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 30px;
}

@-webkit-keyframes grow-bottom {
    0% {
        padding-top: 70px;
    }
    100% {
        padding-top: 0px;
    }
}

@keyframes grow-bottom {
    0% {
        padding-top: 70px;
    }
    100% {
        padding-top: 0px;
    }
}

@-webkit-keyframes wiggle {
    0% {
        padding-left: 0px;
    }
    50% {
        padding-left: 20px;
    }
    100% {
        padding-left: 0px;
    }
  }

@keyframes wiggle {
    0% {
        padding-left: 0px;
    }
    50% {
        padding-left: 20px;
    }
    100% {
        padding-left: 0px;
    }
  }

.Hyperlapse__introbutton:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media screen and (max-width: 1000px) {
    .Hyperlapse__introtitle {
        position: absolute;
        bottom: 190px;
        color: white;
        font-size: 40px;
        line-height: 39px;
        font-weight: 600;
        letter-spacing: -0.5px;
        margin-left: 20px;
        margin-right: 20px;
        overflow: hidden;
    }
    
    .Hyperlapse__introsubtitle {
        position: absolute;
        bottom: 120px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
    }    

    .Hyperlapse__introbutton {
        position: absolute;
        bottom: 30px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: transparent;
        padding: 12px;
    }
}

@media screen and (max-width: 350px) {
    .Hyperlapse__introtitle {
        position: absolute;
        bottom: 150px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.5px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .Hyperlapse__introsubtitle {
        position: absolute;
        bottom: 80px;
        color: white;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
    }    

    .Hyperlapse__introbutton {
        position: absolute;
        bottom: 30px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: transparent;
    }
}
.Block {
    height: 300px;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100vw - 20px);
    margin-top: 20px;
    vertical-align: top;
    display: inline-block;
    overflow: hidden;
    position: relative;
    -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
            animation: fadein 4s;
}

.Block__image {
    pointer-events: none;
    height: 298px;
    width: calc(100% - 2px);
    object-fit: cover;
    border-radius: 10px;
}

.Blocks {
    top: 0px;
    left: 0px;
    width: 100%;
    margin-top: 20px;
}

.Block__expand {
    display: block;
    float: right;
    color: #2725258f;
    font-family: 'Material Icons Outlined';
    width: 50px;
    height: 50px;
    z-index: 10000;
    position: relative;
    background: transparent;
    border: none;
    font-size: 30px;
    margin-right: 20px;
    margin-top: -40px;
    border: none;
    background: none;
}

.Block__link {
    position: absolute;
    right: 20px;
    top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-left: 30px;
    margin-top: 10px;
    font-family: 'Namu';
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    background-color: transparent;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    border: 1px solid #2725258f;
    color: black;
    transition: 0.5s all;
}

.Block__link:hover {
    background-color: black;
    color: white;
    transition: 0.5s all;
}


.Block__text {
    pointer-events: none;
    color: black;
    width: 50%;
    margin-left: 30px;
    margin-top: -270px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    font-family: 'Manrope';
    letter-spacing: 0px;
}

.Block__text--hidden {
    font-size: 14px;
    pointer-events: none;
    color: rgb(56, 56, 56);
    width: 80%;
    margin-left: 25px;
    margin-top: 7px;
    font-weight: 500;
    font-family: 'Namu';
}

.CopyrightBlock {
    margin-top: 70px;
    margin-left: 20px;
    color: #6d6d6d;
    font-size: 15px;
    font-weight: 800;
}

@media screen and (min-width: 600px) {

    .Block__expand {
        display: none;
    }

    .Block__text {
        font-size: 16px;
        margin-top: -450px;
        font-size: 25px;
        line-height: 29px;
        font-weight: 600;
        margin-left: 45px;
        position: relative;
        max-width: 50%;
        letter-spacing: 0px;
        font-family: 'Namu';
    }

    .Block__text--hidden {
        display: none;
        margin-left: 25px;
    }

    .Block__link {
        position: absolute;
        right: 50px;
        top: 50px;
        margin-top: 0px;
        font-size: 18px;
        margin-left: 45px;
        padding-left: 15px;
        padding-right: 15px;
        color: #2b2424;
        border: 1px solid #443c3c81;
        font-weight: 500;
        font-family: 'Namu';
    }

    .Block:hover .Block__text--hidden {
        display: block;
        margin-left: 30px;
        margin-top: 30px;
        font-size: 20px;
    }

    .Block__image {
        height: 250px;
        border: 1px solid #ebebeb;
        border-radius: 15px;
    }


    .Blocks {
        margin-top: 40px;
        width: 600px;
        margin-left: calc(50vw - 300px);
    }

    .Block {
        height: 300px;
        margin-left: 0px;
        margin-right: 10px;
        margin-top: -10px;
        width: 290px;
    }
}

.CopyrightBlock__link {
    text-decoration: none;
    color: #cacaca;
}

@media screen and (min-width: 1000px) {


    .Blocks {
        width: 1400px;
        margin-left: calc(50vw - 500px);
    }

    .Block__image {
        height: 480px;
    }

    .Block {
        margin-bottom: 30px;
        margin-right: 20px;
        height: 480px;
        width: 470px;
    }
}

@media screen and (min-width: 1400px) {
    .Blocks {
        width: 1400px;
        margin-left: calc(50vw - 700px);
    }

    .Block {
        width: 670px;
    }
}


@media screen and (min-width: 1800px) {
    .Blocks {
        width: 1800px;
        margin-left: calc(50vw - 900px);
    }

    .Block {
        width: 870px;
    }
}

@media screen and (min-width: 600px) {
    
    .Block1 {
    }

    .Block2 {
    }

    .Block__image1 {

    }

    .Block__image2 {
    }
}
.Hamburger {
    border: none;
    background: none;
    width: 40px;
    height: 40px;
    margin-top: -10px;
}

.Hamburger__button {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    /* border: 3px solid #fff; */
}

.Hamburger__button__lines {
    width: 18px;
    height: 2px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
}

.Hamburger__button__lines::before,
.Hamburger__button__lines::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 2px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
}

.Hamburger__button__lines::before {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
}

.Hamburger__button__lines::after {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
}

 .Hamburger__button.open .Hamburger__button__lines {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
 .Hamburger__button.open .menu-hamburger__button__lines::before {
    -webkit-transform: rotate(45deg) translate(35px, -35px);
            transform: rotate(45deg) translate(35px, -35px);
  }
  .Hamburger__button--open .menu-hamburger__button__lines::after {
    -webkit-transform: rotate(-45deg) translate(35px, 35px);
            transform: rotate(-45deg) translate(35px, 35px);
  }
.Header {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 60px;
    width: 100vw;
    z-index: 100000;
    transition: height 0.4s, background-color 0.4s;
    white-space: nowrap;
}

.Header--open {
    height: 100vh;
    background-color: white !important;
    transition: height 0.4s, background-color 0.4s;
}

.Header__menu__button__text {
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
    margin-right: -15px !important;
    font-size: 18px;
    letter-spacing: -0.2px;
    font-weight: 500;
    margin-right: 5px;
    font-family: 'Manrope';
}

.Header__logo {
    position: relative;
    margin-top: 40px;
    text-align: left;
    padding-left: 40px;
    font-family: 'Poppins';
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    fill: white;
    mix-blend-mode: difference;
    font-size: 36px;
    -webkit-transform: font-size 0.4s;
            transform: font-size 0.4s;
    letter-spacing: -1.5px;
    z-index: 10000001;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
}

.Header__menu__button {
    text-align: right;
    border: none;
    background: none;
    color: black;
    margin-top: 14px;
    padding-right: 40px;
    cursor: pointer;
    width: 100vw;
    position: fixed;
    top: 0px;
    right: 0px;
}

.Header__menu__button:hover {
    opacity: 0.8;
}

.Header__menu__text {
    pointer-events: none;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 15px;
    margin-top: 6px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: top;
}

.Header__menu__icon {
    pointer-events: none;
    display: inline-block;
    vertical-align: top;
    font-weight: 100;
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
    background: none;
    border: none;
    cursor: pointer;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    margin-top: 0px;
    font-size: 26px !important;
    font-family: 'Material Icons Outlined';
}


.Header__logo--smaller {
    margin-top: 8px;
    font-size: 28px;
    -webkit-transform: font-size 0.4s;
            transform: font-size 0.4s;
}

.Header__info {
    color: black;
    font-family: 'Manrope';
    font-size: 40px;
    top: 250px;
    margin-left: 150px;
}

.Header__info__title {
    display: inline-block;
    margin-right: 50px;
    font-weight: 400;
    width: 150px;
    font-size: 30px;
    -webkit-text-decoration: double;
            text-decoration: double;
    color: #c0c0c0;
    text-transform: lowercase;
}

.Header__info__value {
    display: inline-block;
    font-weight: 700;
    font-size: 30px;
    text-decoration: unset;
    color: black;
}

.Header__info__box {
    display: block;
    margin-top: 0px;
    text-decoration: none;
}

.Header__info__box:hover {
    opacity: 0.75;
}

.Header__info__hello {
    color: #a8a8a8;
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    font-family: 'Manrope';
}

@media screen and (max-width: 1000px) {   


    .Header__info__hello {
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 20px;
        word-wrap: normal;
    }

    .Header__info__title {
        display: block;
        font-size: 15px;
    }
    
    .Header__info__value {
        display: block;
        font-size: 18px;
    }

    .Header__info__box {
        margin-top: 25px;
    }
    
    .Header__info {
        margin-left: 20px;
        font-size: 30px;
        padding-bottom: 25px;
        top: 75px;
        width: calc(100vw - 40px);
    }

    .Header__logo {
        margin-top: 12px;
        margin-left: -5px; 
        font-size: 28px;
        padding-left: 20px;
    }   

    .Header__menu__button {
        padding-top: 5px;
        padding-right: 20px;
    }

    .Header__logo--smaller {
        font-size: 26px;
    }

    .Header__menu__text {
        display: none;
    }

    .Header__menu__button__text {
        margin-top: 8px;
        font-size: 13px;
        margin-right: -1px !important;
    }
}
.Product {
    padding-top: 100px;
    height: 90vh;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: -1;
    background-color: black;
    text-align: center;
}

.Product__name {
    margin-top: 50px;
}

.Product__image {
    top: 20px;
    width: 70vw;
}

@media screen and (max-width: 1000px) {
    .Product {
        pointer-events: all;
        height: 75vh;
        opacity: 1;
        z-index: 10;
        overflow-y: hidden;
    }
}
